@import "../../global.scss";

.s__career {
    width: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
}
.s__career-container {
    padding: 5em 0;
    display:flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: auto;
    max-width: 1600px;
    overflow: hidden;
}
.s__career-title {
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 1em;
    width: 100%;
    color: #0a2540;

    @include breakpoints('small') {
        font-size: 2em;
        text-align: left;
    }
}
.s__career-content {
    position: relative;
    width: 100%;
    overflow: visible;
    // background-color: purple;

    @include breakpoints('small') {
        margin-left: 10%;
    }
}
.s__career-list-content-wrap{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1em;
    z-index: 1;
    max-width: 1300px;
    overflow: visible;

    @include breakpoints('small') {
        padding: 9%;
        flex-direction: row;
    }
}
.s__career-list-left {
    width: 100%;
    font-size: 1em;
    text-align: center;
    transition: all .3s ease;
    z-index: 1;
    margin-right: 1em;
    h2 {
        color: rgba(187, 104, 104, 0.781);
        font-family: 'Roboto', sans-serif;
    }
    h1 {
        color: #0a2540;
    }
    @include breakpoints('small') {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
    }
}
.s__career-list-marker {
    position: absolute;
    width: 100px;
    left: -63px;
    z-index: 1;
    transition: all .5s ease;
}
.s__career-list-left::before {
    
    // @include breakpoints('small') {
    //     content: '------->';
    //     position: absolute;
    //     left: 0;
    // }
}
.s__career-list-right {
    // background-color: grey;
    z-index: 1;
    width: 100%;
    box-shadow: 0 0 0 1px rgba(53, 72, 91, .14), 0 2px 2px rgba(0, 0, 0, .03), 0 4px 4px rgba(0, 0, 0, .04), 0 10px 8px rgba(0, 0, 0, .05), 0 15px 15px rgba(0, 0, 0, .06), 0 30px 30px rgba(0, 0, 0, .07), 0 70px 65px rgba(0, 0, 0, .09);
    border-radius: 7px;

    @include breakpoints('small') {
        width: 50%;
    }
}
.s__career-list-p-wrap {
    background-color: white;
    z-index: 1;
    padding: 1.5em;
    font-size: 1em;

    li::after {
        display: none !important;
    }

    p {
        padding: .3em;
        color: #57606a;
    }
    // p::before {
    //     content: '</> '; 
    //     color: #967e5f;
        
    // }
    @include breakpoints('large') {
        p {
            font-size: 1.2em;
        }
    }
    @include breakpoints('xtra-large') {
        p {
            font-size: 1.3em;
        }
    }
}
.p__text-bold {
    color: black;
    font-weight: 600;
    text-transform: uppercase;
}
.s__career-ul {
    overflow: visible;
    list-style-type: none;
    padding-left: 0;
    
    @include breakpoints('small') {
        padding-inline-start: 40px;
    }
}
.s__career-ul li {
    overflow: visible;
    display: flex;
    flex-direction: column-reverse;
    padding-top: 2em;

    &:hover {
        transition: all .3s ease;
        img {
            transition: all .3s ease;
            opacity: 1;
        }
        .s__career-list-left {
            @include breakpoints('small') {
                transition: all .3s ease;
                transform: scale(1.1);
            }
        }
        .s__career-list-marker {
            transition: all .3s ease;
            transform: rotateZ(45deg) translateY(-20px) !important;
            
        }
    }

    @include breakpoints('small') {
        position: relative;

    }
}
.s__career-ul li::after {
    
    @include breakpoints('small') {
        content: '';
        position: absolute;
        display: block;
        left: 0px;
        top: 0;
        height: 100%;
        border-left: 2px #dadfe9 solid;
    }    
}
.img-bg {
    width: 100%;
    max-height: 400px;
    display: flex;
    justify-content: center;
    top: 0;
    z-index: 0;

    @include breakpoints('small') {
        position: absolute;
        display: block;
        right: -58%;
    }
    
    img {
        transition: all .3s ease;
        width: 100%;
        max-width: 2400px;
        // height: 200px;
        opacity: .1;
        padding: 0 2em;

        @include breakpoints('small') {
            padding: 0;
            opacity: .2;
        }
    }
}
.s__career-img--airforce {
    max-width: 167px !important;

    @include breakpoints('small') {
        margin-left: 7%;
    }
}


