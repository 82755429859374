@import "../../global.scss";

.popup{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.652);
    position: fixed;
    transition: all 0.3s linear;
    bottom: 0;
    z-index: 10;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .wrapper{
        height: 70%;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        display: flex;
        img{
            height: 100%;
            display: block;
            border-radius: 25px;
        }
    }
    .exit{
        display: flex;
        overflow: hidden;
        

        button{
            background-color: $darkColor;
            overflow: hidden;
            color: $whiteColor;
            font-size: 40px;
            font-weight:1000;
            margin: 20px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 5px;
            border-width: 2px;
            border-color: $whiteColor;
            border-radius: 10px;
        }
    }
}