@import "../../global.scss";
.contact{
    background-color: $darkColor;
    display: flex;
    flex-direction: column;
    font-family: 'Kalam', cursive;
    overflow: hidden;
    // margin-top: 3em;
    
    width: 100%;
    align-items: center;

    .container{
        // flex: .95;
        display: flex;
        flex-direction: column;
        padding: 2em 1em;
        overflow: hidden;
        max-width: 1680px;
        width: 98%;
        // margin: auto;
        .top{
            text-align: center;
            background-color: $darkColor;
            display: flex;
            overflow: hidden;
            justify-content: center;
            align-items: center;
            
            h1{
                flex: .5;
                text-align: center;
                color: $whiteColor;
                text-decoration: none;
                overflow: hidden;
                font-size: 1.2em;
              
                .h1_blue{
                    color: $redColor;
                }
            }
            .top_right{
                flex: .5;
                // width: 100%;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                
                .social_media{
                    justify-content: left;
                    align-items: center;
                    color: white;
                    overflow: hidden;

                    img{
                        max-width: 27px;
                        background-color: white;
                        border-width: 5px;
                        border-style: double;
                        border-color: $darkColor;
                        border-radius: 25%;
                        align-items: center;
                        
                        &:hover{
                            border-color: $blueColor;
                            transition: all 0.3s ease;
                        }
                    }
                    @include breakpoints('xtra-small') {
                        img {
                            max-width: 40px;
                        }
                    }
                    @include breakpoints('small') {
                        img {
                            max-width: 50px;
                        }
                    }
                }
                .resume{
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    overflow: hidden;
                    .download{
                        height: 30px;
                        margin-bottom: 5px;
                        margin-right: 10px;
                        overflow: hidden;
                    }
                    a{
                        text-decoration: none;
                        overflow: hidden;
                    }
                    h1{
                        font-size: 25px;
                        // height: 100%;
                        width: 100%;
                        color: white;
                        text-decoration: none;
                        overflow: hidden;
                        
                        &:hover{
                            color: $redColor;
                            transition: all 0.3s ease;
                        }
                    }
                }
                
                
            }
            @include breakpoints('small') {
                h1 {
                    font-size: 2em;
                }
            }
         
        }
        .middle{
            // flex:.8;
            background-color: $darkColor;
            display: flex;
            width: 100%;
    
            form{
                display: flex;
                width: 100%;
                // height: 100%;

                .form_container{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    position: relative;
                    width: 100%;
                    // flex: 1;
                    .left{
                        display: flex;
                        flex-direction: column;
                        flex: .5;
                        // height: 100%;
                        overflow: hidden;
                        width: 100%;
                        label{
                            // height: 100px;
                            min-height: 70px;
                            margin-bottom: 5px;
                        }
                     
                        input{
                            background-color: $greyColor;
                            min-height: 50px;
                            // width: 100%;
                            padding-left: 3%;
                            font-size: 1.5em;
                            font-weight: 1000;
                            border-width: 10px;
                            border-style: double;
                            border-color: $darkColor;
                            color: $darkColor;
                        }
                        .submit{
                            width: 100%;
                            // height: 100%;
                            // min-height: 200px;
                            background-color: $greyColor;
                            background-image: url("./send.gif");
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: center;
                            transition: 0.5s;
                            font-size: 5em;
                            text-align: right;
                            // padding-right: 15%;
                            color: rgba(0, 0, 0, 0);
                            padding: .5em 0;
                            overflow: hidden;

                            &:hover{
                                background-position-x: -50px;
                                cursor: pointer;
                                transition: 0.5s;
                                color: black;
                            }

                            @include breakpoints('small') {
                                height: 100%;
                            }
                        }
                    }
                    .right{
                        flex: 0.5;
                        // height: 100%;
                        display: flex;
                        // width: 1;
                        // display: block;
                        min-height: 300px;
                        overflow: hidden;
                        textarea{
                            border-width: 10px;
                            border-style: double;
                            border-color: $darkColor;
                            font-size: 1.5em;
                            // display: block;
                            // flex: 0.5;
                            padding: .5em;
                            width: 100%;
                            background-color: $greyColor;
                            resize: none;
                        }
                    }
                    @include breakpoints('small') {
                        min-height: 600px;
                        flex-direction: row;
                    }
                }
            }
            
            
        }
        @include breakpoints('small') {
            padding: 10em 2em;
            
        }
     
    }
    .footer_container{
        width: 100%;
        padding: 10em 0;
        font-size: small;
        position: relative;
        color: white;
        text-align: center;
        padding-top: 15px;
        background-color: #76767659;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        overflow: hidden;
    }
   
}
// @include mobile{
//     .contact .container{
//         flex: .95;
//         display: flex;
//         flex-direction: column;
//         padding-left: 1em;
//         padding-right: 1em;
//         padding-top: 5em;
//         padding-bottom: 5em;
//         overflow: hidden;
//         .top{
//             flex: .2;
//             text-align: center;
//             background-color: $darkColor;
//             font-size: 1em;
//             display: flex;
//             overflow: hidden;
//             h1{
//                 flex: .5;
//                 text-align: center;
//                 color: $whiteColor;
//                 text-decoration: none;
//                 overflow: hidden;
              
//                 .h1_blue{
//                     color: $redColor;
//                 }
//             }
//             .top_right{
//                 justify-content: center;
//                 align-items: center;
//                 color: white;
//                 flex: .5;
//                 height: 100%;
//                 width: 100%;
//                 overflow: hidden;
            
//                 .social_media{
//                     img{
//                         height: 100%;
//                         background-color: $greyColor;
//                         border-width: 5px;
//                         border-style: double;
//                         border-color: $darkColor;
//                         border-radius: 25%;
//                         height: 40px;
//                         align-items: center;
                        
//                         &:hover{
//                             border-color: $blueColor;
//                             transition: all 0.3s ease;
//                         }
//                     }
//                 }
          
//             }
        
          
         
//         }
//         .middle{
//             flex:.8;
//             background-color: $darkColor;
//             display: flex;
//             width: 100%;
    
//             form{ 
//                 display: flex;
//                 width: 100%;
//                 height: 100%;
//                 flex-direction: column;

//                 .form_container{
//                     display: flex;
//                     flex-direction: column-reverse;   
//                     justify-content: right;
//                     position: relative;
//                     flex: 1;
//                     .left{
//                         display: flex;
//                         flex-direction: column;
//                         flex: .5;
//                         height: 100%;
//                         width: 100%;
//                         padding-right: 0px;
//                         label{
//                             height: 100px;
//                             margin-bottom: 1%;
//                         }
                     
//                         input{
//                             background-color: $greyColor;
//                             padding-left: 3%;
//                             width: 100%;
//                             height: 100%;
//                             font-size: 2em;
//                             font-weight: 1000;
//                             border-width: 10px;
//                             border-style: double;
//                             border-color: $darkColor;
//                             color: $darkColor;
//                         }
//                         .submit{
//                             width: 100%;
//                             background-image: url("./send.gif");
//                             background-repeat: no-repeat;
//                             background-size: cover;
//                             background-position: center;
//                             transition: 0.5s;
//                             font-size: 5em;
//                             text-align: right;
//                             padding-right: 15%;
//                             color: rgba(0, 0, 0, 0);

//                             &:hover{
//                                 background-position-x: -50px;
//                                 cursor: pointer;
//                                 transition: 0.5s;
//                                 color: black;
//                             }
//                         }
//                     }
//                     .right{
//                         flex: 0.5;
//                         padding-left: 0px;
//                         height: 100%;
//                         width: 100%;
//                         overflow: hidden;
//                         textarea{
//                             border-width: 10px;
//                             border-style: double;
//                             border-color: $darkColor;
//                             font-size: 2em;
//                             display: block;
//                             flex: 0.5;
//                             padding: 5%;
//                             width: 100%;
//                             height: 100%;
//                             background-color: $greyColor;
//                             resize: none;
                          
//                         }
//                     }
//                 }
//             }
            
            
//         }
     
//     }
// }