@import "../../global.scss";

.skills{
    background-color: $greyColor;
    display: block;
    overflow: hidden;
    text-align: center;
    padding: 75px 40px 100px;
    border-top: 1px solid #d0d7de;
    border-bottom: 1px solid #d0d7de;

    .container{
        position: relative;
        display: block;
        max-width: 700px;
        height: 100%;
        overflow: hidden;
        margin: 0px auto;
        
        div{
            overflow: hidden;
            padding-bottom: 20px;
        }
        .header{
            display: flex;
            justify-content: center;
            margin-bottom: 2em;
            text-align: center;
            color: #0a2540;
            div{
                font-family: 'Kalam', cursive;
                font-size: 3.5em;    
                font-weight: 1000;
                text-align: center;
            }
        }
        .img-wrapper{
            margin: 0px 10px 0px 10px;
            position: relative;
            width: 130px;
            
            img{
                margin-top: 10px;
                margin-bottom: 10px;
                display: inline-block;
                vertical-align: middle;
                width: 130px;
                opacity: .5;
                filter: grayscale(100%);
                transition: all .5s ease;

                &.view{
                    opacity: 1;
                    filter: none;
                    transition: all .5s ease;
                    
                    
                }
                &.no-view{
                    opacity: .5;
                    filter: grayscale(100%);
                    transition: all .5s ease;
                }
                &:hover{
                    opacity: 1;
                    transform: scale(1.2);
                    filter: none;
                    transition: all .3s ease;
                }
            }
            
        }
       
    }
}

@include mobile{
    .skills{
        .container{
            max-width: 600px;
            .header{
                div{
                    font-size: 2.7em;
                }
            }
            .img-wrapper{
                width: 120px;
                margin: 0 auto;
                img{
                    display: inline-block;
                    vertical-align: middle;
                    width: 120px;
                    margin: 5px auto 0 5px;
                    opacity: 1;
                    filter: none;

                    &.view{
                        opacity: 1;
                        filter: none;
                        transition: all .5s ease;
                        
                        
                    }
                    &.no-view{
                        opacity: .5;
                        filter: grayscale(100%);
                        transition: all .5s ease;
                    }
                    &:hover{
                        opacity: 1;
                        filter: none;
                        transition: all .5s ease;
                    }
                }
            }
           
        }
    }
}