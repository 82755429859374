.page{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #1b1b1b;
    a{
        height: 100vh;
        width: 100vw;
        max-width: 2000px;
        max-height: 2000px;
        display: flex;
        flex-direction: column;
        background-color: #1b1b1b;
    }
   
}