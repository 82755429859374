@import "../../global.scss";

.menu{
    font-family: 'Kalam', cursive;
    width: 250px;
    height: 100vh;
    top: 0;
    right: -350px;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $mainColor;
    position: fixed;
    transition: all .5s ease;

    &.active{
        right: 0;
    }

    ul {
        color: white;
        font-size: 30px;
        font-weight: 200;
        width: 60%;
        margin: 0;
        padding: 0;
        list-style: none;

        li{
            margin-bottom: 20px;
            a{
                color: inherit;
                text-decoration: none;
                font-size: inherit;

               
            }

            &:hover{
                font-weight: 1000;
                color: $redColor;
            }
        }
        .social{
            img{
                background-color: white;
                border-width: 5px;
                border-style: double;
                border-color: $darkColor;
                border-radius: 25%;
                max-width: 27px;
                align-items: center;
                
                &:hover{
                    border-color: $blueColor;
                    transition: all 0.3s ease;
                }
            }
        }
    }
}
@include mobile{
    .menu{
        font-family: 'Kalam', cursive;
        width: 100vw;
        height: 100vh;
        top: 0;
        right: -100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $mainColor;
        position: fixed;
        transition: all 1s ease;
    
        &.active{
            right: 0;
        }
    
        ul {
            color: white;
            font-size: 30px;
            font-weight: 200;
            width: 100%;
            margin: 0;
            align-items: center;
            justify-content: center;
            list-style: none;
            display: flex;
            flex-direction: column;
            
    
            li{
                
                margin-bottom: 20px;
                a{
                    color: inherit;
                    text-decoration: none;
                    font-size: inherit;
    
                   
                }
    
                &:hover{
                    font-weight: 1000;
                    color: $redColor;
                }
            }
            .social{
                img{
                    background-color: white;
                    border-width: 5px;
                    border-style: double;
                    border-color: $darkColor;
                    border-radius: 25%;
                    max-width: 60px;
                    align-items: center;
                    
                    &:hover{
                        border-color: $blueColor;
                        transition: all 0.3s ease;
                    }
                }
            }
        }
    }
}