@import "../../global.scss";

.topbar{
    width: 100%;
    height: 70px;
    background-color: $mainColor;
    color: $mainColor;
    position: fixed;
    transition: all 1s ease;
    top: 0;
    z-index: 30;

    .wrapper{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left{
            display: flex;
            align-items: center;
            margin-left: 20px;
            flex: .3;
            img{
                height: 30px;
                border-radius: 50%;
                background-color: rgb(255, 255, 255);
                transition: 0.5s;

                &:hover{
                    height: 60px;
                    transition: 0.5s;
                    background-color: $blueColor;
                }
            }

            .logo{
                font-family: 'Estonia', cursive;
                align-items: center;
                justify-content: center;
                font-size:40px;
                color: white;
                text-decoration: none;
                margin-left: 10px;

                &:hover{
                    font-weight: 1000;
                    
                }
            }
        }
        .middle{
            @include topBar{
                display: none;
            }
            .navItems{
                font-family: 'Kalam', cursive;
                font-size:20px;
                font-weight: 0;
                color: white;     
                margin-left: 30px;
                text-decoration: none;
                &:visited{
                    color: $whiteColor;
                }
                &:hover{
                    font-weight: 1000;
                    color: $blueColor;
                 
                }
            }
        }
        .email{
            font-family: 'Kalam', cursive;
            font-size: 15px;
            color: white;
            position: relative;
            text-decoration: none;
            
            &:hover{
                font-weight: 1000;
                color: $redColor;
            }
        }
        .right{
            margin-right: 20px;
            .menuButton{
                display: none;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;
                width: 32px;
                height: 25px;

                @include topBar{
                    display: flex;
                }

                

                span{
                    width: 100%;
                    height: 3px;
                    background-color: white;
                    transition: all 1s ease;
                    transform-origin: right;
                }
            }
        }
    }

    &.active{
        background-color: $mainColor;
        color: white;
        transition: all 1s ease;
        
        .menuButton{
            transition: all 1s ease;
            span{
                &:nth-child(1){
                    background-color: white;
                    transform: rotate(-45deg);
                }
                &:nth-child(2){
                    background-color: white;
                    transform: translateX(9px);
                }
                &:nth-child(3){
                    background-color: white;
                    transform: rotate(45deg);
                }
            }
        }
        .middle{
            transition: all 1s ease;
            display: none;
            
            
        }
    }
}
@include mobile{
    .topbar{
        width: 100%;
        height: 70px;
        background-color: $mainColor;
        color: $mainColor;
        position: fixed;
        transition: all 1s ease;
        top: 0;
    
        .wrapper{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
    
            .left{
                display: flex;
                align-items: center;
                margin-left: 10px;
                flex: 1;
                img{
                    height: 30px;
                    border-radius: 50%;
                    background-color: rgb(255, 255, 255);
                    transition: 0.5s;
                    margin-right: .5em;
    
                    &:hover{
                        height: 30px;
                        transition: 0.5s;
                        background-color: $blueColor;
                    }
                }
    
                .logo{
                    font-family: 'Estonia', cursive;
                    align-items: center;
                    justify-content: center;
                    font-size:30px;
                    color: white;
                    text-decoration: none;
                    margin-left: 2px;
    
                    &:hover{
                        font-weight: 1000;
                        
                    }
                }
            }
            .middle{
                @include topBar{
                    display: none;
                }
                .navItems{
                    font-family: 'Kalam', cursive;
                    font-size:20px;
                    font-weight: 0;
                    color: white;
                    text-decoration: none;
                    margin-left: 30px;
                    
                    &:hover{
                        font-weight: 1000;
                        color: $blueColor;
                    
                    }
    
                }
                
            }
            .email{
                flex: 1;
                font-family: 'Kalam', cursive;
                font-size: .7rem;
                color: white;
                position: relative;
                text-decoration: none;
                padding-right: 10px;
                
                &:hover{
                    font-weight: 1000;
                    color: $redColor;
                }
            
            }
    
            .right{
                margin-right: 20px;
                .menuButton{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    cursor: pointer;
                    width: 32px;
                    height: 25px;
                    
    
                    span{
                        width: 100%;
                        height: 3px;
                        background-color: white;
                        transition: all 1s ease;
                        transform-origin: right;
                    }
                }
            }
        }
    
        &.active{
            background-color: $mainColor;
            color: white;
            transition: all 1s ease;
            
            .menuButton{
                transition: all 1s ease;
                span{
                    &:nth-child(1){
                        background-color: white;
                        transform: rotate(-45deg);
                    }
                    &:nth-child(2){
                        background-color: white;
                        transform: translateX(9px);
                    }
                    &:nth-child(3){
                        background-color: white;
                        transform: rotate(45deg);
                    }
                }
            }
            .middle{
                transition: all 1s ease;
                display: none;
                
                
            }
        }
    }
}