@import url('https://fonts.googleapis.com/css2?family=Estonia&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:wght@400;500;600;700&family=Roboto&display=swap');

$mainColor: rgb(8, 7, 8);
$blueColor: rgb(109, 109, 255);
$whiteColor: rgb(246, 246, 246);
$greyColor: rgb(241, 241, 241);
$redColor: red;
$darkColor: rgb(20 ,20 ,20);
$bulletColor: rgb(150, 126, 95);
$goldColor: rgb(223, 210, 138);
$tanColor: rgba(187, 104, 104, 0.781);
$width: 850px;
$topBar: 940px;

$breakpoints: (
    'xtra-small':  ( min-width:  481px ),
    'small':       ( min-width:  769px ),
    'medium':      ( min-width:  992px ),
    'large':       ( min-width: 1026px ),
    'xtra-large':       ( min-width: 1201px ),
    'xtra-xtra-large':       ( min-width: 1301px )
) !default;

@mixin breakpoints($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
      @media #{inspect(map-get($breakpoints, $breakpoint))} {
        @content;
      }
    }
  }

@mixin mobile {
    @media (max-width: #{$width} ) {
        @content
    }
}
@mixin breakpoint() {
    @media (max-width: #{$width} ) {
        @content
    }
    @media (max-width: #{$width} ) {
        @content
    }
    @media (max-width: #{$width} ) {
        @content
    }
}

@mixin topBar {
    @media (max-width: #{$topBar} ) {
        @content
    }
}

