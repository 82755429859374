@import "../../global.scss";

.intro{
    height: 100vh;
    background-color:black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    

    .backgroundVid{
      width: 100vw;
      height: 100vh;
      z-index: 0;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      video{
          object-fit: cover;
          width: 100%;
          height: 100%;
          position:absolute;
          z-index: 0;
      }
    }
    .loading{
      background-color: $darkColor;
      position: fixed;
      height: 120%;
      width: 100%;
      z-index: 30;
      display: flex;
      align-items: center;
      justify-content: center;
      
      h1{
        position: absolute;
        color: white;
        align-items: center;
        text-align: center;
      }
    }

    .center{
        width: 100%;
        .wrapper{
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
            
          .drag_me{
            font-weight: 1000;
            font-size: 20px;
            color: $whiteColor;
            font-family: 'Kalam', cursive;
            animation: blink 4s linear infinite;
            margin-bottom: 5px;
            overflow: hidden;
            z-index: 5;
          }
          @keyframes blink {
            50% {
              opacity: 0;
            }
          }
     
          .avatarBorder{
            margin-bottom: 40px;
            display: flex;
            min-width: 300px;
            width: 90%;
            height: 210px;
            border-width: 10px;
            border-radius: 15px;
            border-style: double;
            background: linear-gradient(#0b0c0e, rgba(56, 45, 45, 0.46));
         
            .avatar{
              width: 100%;
              overflow: hidden;
                    
              img{
                margin-left: auto;
                margin-right: auto;
                display: block;
              }
            }
            @include breakpoints('xtra-small') {
              max-width: 400px;
            } 
          }

          .headerContainer{
            text-align: center;
            display: flex;
            flex-direction: column;
          
            h1{
                padding-left: 2px;
                padding-right: 2px;
                align-items: center;
                justify-content: center;
                font-family: 'Estonia', cursive;
                color: white;
                font-size:5em;
                overflow: hidden;

                @include breakpoints('small') {
                  font-size:5em;
                }
                @include breakpoints('medium') {
                  font-size:6em;
                }
        
            }
            h2{
                font-family: 'Estonia', cursive;
                color: $blueColor;
                font-size: 3em;
                font-weight: inherit;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                
                .red{
                  color: $redColor;
                }

                @include breakpoints('small') {
                  font-size:5em;
                }
                @include breakpoints('medium') {
                  font-size:6em;
                }
            }
          }
          .headerContainer .red {
            font-family: 'Estonia', cursive;
          }
          .downArrow{
            display: flex;
            flex-direction: column;
            justify-content: center; 
            width: 100%;
            text-align: center;
            margin-top: 10vh;
            overflow: hidden;
            // margin-bottom: 5%;
            scroll-behavior: smooth;
            align-items: center;
           
            img {
                position: relative;
                bottom: 0;
                height: 50px;
                width: 100px;
                font-family: 'Cormorant Garamond', serif;
                text-transform: uppercase;
                overflow: hidden;
                scroll-behavior: smooth;
                // margin-bottom: 15%;
                opacity: 0.3;
                animation: blink 2s linear infinite;
                // transform: translateX(-50%);
            }
    
            }
        }
    }
  }
// @include mobile{
//   .intro{
//     .center{
//         flex: 1;
//         z-index: 1;
//         height: 100%;
//         .wrapper{
//           width: 400px;
//           height: 100%;
//           display: flex;
//           flex-direction: column;
//           justify-content: center;
//           align-items: center;
//           position: relative;
          
            
//           .drag_me{
//             font-weight: 1000;
//             font-size: 2em;
//             color: $whiteColor;
//             font-family: 'Kalam', cursive;
//             animation: blink 4s linear infinite;
//             overflow: hidden;
//           }
     
//           .avatarBorder{
//             margin-bottom: 2%;
//             width: 70%;
//             height: 200px;
//             border-width: 10px;
//             border-radius: 15px;
//             border-style: double;
//             background: linear-gradient(#0b0c0e, rgba(56, 45, 45, 0.46));
         
//             .avatar{
//               width: 100%;
//               height: 100%;
//               overflow: hidden;
                    
//               img{
//                 margin-left: auto;
//                 margin-right: auto;
//                 display: block;
//               }
//             } 
//           }

//           .headerContainer{
//             text-align: center;
//             display: flex;
//             flex-direction: column;
          
//             h1{
//                 z-index: 100;
//                 padding-left: 2px;
//                 padding-right: 2px;
//                 align-items: center;
//                 justify-content: center;
//                 font-family: 'Estonia', cursive;
//                 color: white;
//                 font-size:10em;
//                 overflow: hidden;
        
//             }
//             h2{
//                 font-family: 'Estonia', cursive;
//                 color: $blueColor;
//                 font-size: 5em;
//                 font-weight: inherit;
//                 align-items: center;
//                 justify-content: center;
//                 overflow: hidden;
                
//                 .red{
//                   color: $redColor;
//                 }
//             }
//           }
//           .downArrow{
//             display: flex;
//             flex-direction: column;
//             justify-content: center; 
//             width: 100%;
//             text-align: center;
//             margin-top: 30%;
//             overflow: hidden;
//             margin-bottom: 5%;
//             scroll-behavior: smooth;
//             align-items: center;
           
//             img {
//                 position: absolute;
//                 bottom: 0;
//                 height: 50px;
//                 width: 100px;
//                 font-family: 'Cormorant Garamond', serif;
//                 text-transform: uppercase;
//                 overflow: hidden;
//                 scroll-behavior: smooth;
//                 margin-bottom: 15%;
//                 opacity: 0.3;
//                 animation: blink 2s linear infinite;
//                 transform: translateX(-50%);
//             }
//           }
//         }
//     }
//   }
//     flex-direction: column;
//     align-items: center;
// }