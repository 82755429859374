@import "../../global.scss";

.project_right{
    font-family: 'Ibarra Real Nova', serif;
    color: white;
    // background: linear-gradient(#f6f6f6, #f6f6f6);
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    padding: 5rem 0;
    border-bottom: 1px solid #9696962c;

    @include breakpoints('small') {
        background: #ffffff;;
    }
}

.project_right .container{
    display: flex;
    height: inherit;
    width: 100%;
    max-width: 1680px;
    overflow: visible;
    
}
.project_right .container .work{
    
    height: inherit;
    width: 100%;
    display: flex;
    overflow: visible;
    flex-direction: column-reverse;

    .content_left{
        display: flex;
        align-items: center;
        overflow: visible;
        // flex: .6;
        animation: none;
        margin-top: 2em;

        .wrapper{
            // border-style:double;
            // border-top-width: 8px;
            // border-left-width: 8px;
            // border-right-width: 0;
            // border-bottom-width: 8px;
            // border-color: black;
            // background-color: white;
            width: 100%;
            // max-height: 350px;
            overflow: hidden;

            .info{
                margin: 0 1em;
                height: 100%;
                align-items: center;
                justify-content: center;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                animation: none;
                h1{
                    overflow: hidden;
                    text-align: center;
                    color: $redColor;
                    margin-bottom: 1%;
                    font-size: 2em;
                    font-weight: 600;
                }
                p{
                    overflow: hidden;
                    font-weight: 100;
                    font-size: 1em;
                    color: black;
                    margin: .2em 0;
                }
                .button_wrapper{
                    overflow: hidden;
                    margin-top: 2%;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    display: flex;
    
                    button{
                        display:inline-block;
                        width: 100px;
                        padding:0.5em 1.2em;
                        margin: 0 1em;
                        border-width: 1px;
                        border-radius: 5px;
                        box-sizing: border-box;
                        text-decoration:none;
                        font-family:'Roboto',sans-serif;
                        font-weight:300;
                        color:#FFFFFF;
                        background-color:$blueColor;
                        text-align:center;
                        transition: all 0.2s;
                        cursor: pointer;

                        @include breakpoints('small') {
                            width: 150px;
                            padding: .7em 0;
                        }
                        @include breakpoints('xtra-large') {
                            width: 200px;
                            padding: 1em 0;
                        }
                    }
                    .btn__website {
                        background-color: white;
                        color: black;

                        &:hover{
                            background-color: $goldColor;
                            color: black;
                        }
                    }
                    .btn__github {
                        background-color: white;
                        color: black;

                        &:hover{
                            background-color:black;
                            color: white;
                        }
                    }
                }
                @include breakpoints('xtra-small') {
                    h1 {
                        font-size: 2.5em;
                    }
                    p {
                        font-size: 1.3em;
                    }
                }
                @include breakpoints('xtra-large') {
                    p {
                        font-size: 1.2em;
                    }
                }

            }
            @include breakpoints('small') {
                margin-left: 2em;
            }
            
        }
        @include breakpoints('small') {
            flex: .6;
        }
        @include breakpoints('xtra-xtra-large') {
            min-width: 60%;
        }
    }
    .content_right{
        // flex: .4;
        align-items: center;
        justify-content: center;
        display: flex;
        overflow: visible;
        // padding: 2em 1em 0 1em;
        transition: transform .2s ease;

        &:hover {
            transform: scale(1.05);
        }

        img{
            box-shadow: 0 0 0 1px rgba(53, 72, 91, .14), 0 2px 2px rgba(0, 0, 0, .03), 0 4px 4px rgba(0, 0, 0, .04), 0 10px 8px rgba(0, 0, 0, .05), 0 15px 15px rgba(0, 0, 0, .06), 0 30px 30px rgba(0, 0, 0, .07), 0 70px 65px rgba(0, 0, 0, .09);
            overflow: hidden;
            border-radius: 6px;
            cursor: pointer;
            margin:0 1em;
            
        }
        @include breakpoints('small') {
            flex: .4;
            flex-direction: row;
            padding: 5em;
            max-width: 500px;
            padding: 0em;

            img {
                width: 100%;
            }
        }
        
    }
    @include breakpoints('small') {
        flex-direction: row;
        padding: 1em;
    }
    
}
.project_right .work .img-active {
    background: #aaaaaaea;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    max-width: 100% !important;
    top: 0;
    left: 0;
    z-index: 999999999;
    &:hover {
        transform: none !important;
    }
    img {
        max-width: 80vh;
        width: 100% !important;

        @include breakpoints('small') {
            width: 90% !important;
        }
    }
}
// @include mobile{
//     .project_right .container .work{
//         flex-direction: column-reverse;
//         align-items: center;  

//         .content_left{
//             display: flex;
//             align-items: center;
//             overflow: hidden;
//             flex: .6;
    
//             .wrapper{
//                 border-style: double;
//                 border-top-width: 8px;
//                 border-left-width: 8px;
//                 border-right-width: 8px;
//                 border-bottom-width: 8px;
//                 border-color: black;
//                 background-color: white;
//                 height: 80%;
//                 width: 100%;
//                 margin-left: 5%;
//                 margin-right: 5%;
//                 max-height: 500px;
//                 overflow: hidden;
    
//                 .info{
//                     margin-left: 10%;
//                     margin-right: 10%;
//                     height: 100%;
//                     align-items: center;
//                     justify-content: center;
//                     display: flex;
//                     flex-direction: column;
//                     overflow: hidden;
//                     h1{
//                         font-family: 'Kalam', cursive;
//                         overflow: hidden;
//                         text-align: center;
//                         color: $redColor;
//                         margin-bottom: 1%;
//                         font-size: 30px;
//                         font-weight: 1000;
//                     }
//                     p{
//                         font-family: 'Kalam', cursive;
//                         overflow: hidden;
//                         font-weight: 100;
//                         font-size: 1em;
//                         color: black;

//                     }
            
//                 }
            
                
//             }
            
//         }
//         .content_right{
//             flex: .4;
//             align-items: center;
//             justify-content: center;
//             display: flex;
//             padding-right: 0px;
//             overflow: hidden;
//             margin-top: 5%;
    
//             img{
//                 overflow: hidden;
//                 border-style: double;
//                 border-width: 10px;
//                 border-color: $darkColor;
//                 border-radius: 5%;
//                 width: 100%;
//                 height: 100%;
                
//             }
//         }
//     }
        
// }