@import "../../global.scss";
.AboutMe{
    background-color: $darkColor;
    color: white;
    width: 100%;
    height: 100vh;
    position: relative;
   
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .container{
        width: 100%;
        // height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 2000px;

        .left{
            justify-content: center;
            align-items: center;
            display: flex;
            overflow: hidden;
            margin-bottom: 4vh;
            width: 100%;
           
            .left_content{
                justify-content: center;
                align-items: center;
                margin: 0 1em;
                width: 100%;
                display: flex;
                flex-direction: column;
                overflow: hidden;

                h1{
                    font-size: 3em;
                    line-height: 1;
                    margin-bottom: .5em;
                    overflow: hidden;
                    text-align: center;
                    color: $goldColor;
                    font-family: 'Roboto', sans-serif;
                }
                p{
                    text-indent: 6%;
                    font-family: 'Kalam', cursive;
                    font-size: 1em;
                    overflow: hidden;

                    .blue{
                        color: $blueColor;
                        font-family: 'Roboto', sans-serif;
                    }
                    .red{
                        color: $redColor;
                        font-family: 'Roboto', sans-serif;
                    }
                }
                @include breakpoints('xtra-small') {
                    p {
                        font-size: 1.2em;
                    }
                }
                @include breakpoints('medium') {
                    width: 70%;
                    margin-left: 2em;
                    h1 {
                        font-size: 4em;
                    }
                }
            }
        }
        .right{
            justify-content: center;
            align-items: center;
            display: flex;
            overflow: hidden;
            width: 100%;

            .right_content{
                justify-content: center;
                align-items: center;
                
                width: 100%;
                display: flex;
                overflow: hidden;
                
                .imgContainer{
                    display: flex;
                    position: relative;
                    justify-content: center;
                    overflow: hidden;
                    max-width: 400px;

                    img{
                        width: 100%;
                        overflow: hidden;
                        position: relative;
                        z-index: 5;
                    }
                    @include breakpoints('small') {
                        
                    }
                    @include breakpoints('medium') {
                        margin-right: 30px;
                        max-width: 500px;
                    }
                }
            }
        }
        @include breakpoints('medium') {
            flex-wrap: nowrap;
        }
      }
    }
    // @include mobile{
    //     .container{
    //         flex-direction: column;
    //         align-items: center;
          

    //         .left{
    //             display: flex;
    //             flex: 1;
    //             margin-top: 3em;
    //             margin: auto;
    //             .left_content{
    //                 justify-content: center;
    //                 align-items: center;
    //                 display: flex;
    //                 flex-direction: column;
    //                 margin-top: 4rem;
    //                 margin-left: 0;
    //                 margin-right: 0;
              
    
    //                 h1{
    //                     font-size: 3em;
    //                     margin-bottom: 0px;
    //                 }
    //                 p{
    //                     text-indent: 6%;
    //                     font-family: 'Kalam', cursive;
    //                     font-size: 1rem;
    
    //                     .blue{
    //                         color: $blueColor;
    //                     }
    //                     .red{
    //                         color: $redColor;
    //                     }
    //                 }
    //             }
    //         }
    //         .right{
    //             width: 100%;
    //             flex: .7;
    //             .right_content{
    //                 width: 100%;
    //                 .imgContainer{
    //                     display: flex;
    //                     justify-content: center;
    //                     margin-right: 2em;
    //                     margin-left: 2em;
    //                     img{
    //                     }

    //                 }
    //             }
    //         }
    //     }
        

    // }
    
// }
// @include mobile{
//     .AboutMe{
//         height: 150%;
//     }       
// }
