@import "../../global.scss";

.projects__title {
    font-size: 1.5em;
    text-align: center;
    margin-top: 70px;
    width: 100%;
    color: #0a2540;

    @include breakpoints('small') {
        font-size: 2em;
    }
}