@import "./global.scss";
.app{
    &::-webkit-scrollbar{
        display: none;
    }
    
    *{
        font-family: 'Kalam', cursive;
        &::selection{
            color: $whiteColor;
            background-color: $redColor;
        }
        
    }
    height:100%;
    background-color: #ffffff;
    
    .sections{
        width: 100%;
        height: 100%;
        position: relative;
        top: 0;
        scrollbar-width: none;
        &::-webkit-scrollbar{
            display: none;
        }
       
    }
    

}
html {
    scroll-behavior: smooth;
    &::-webkit-scrollbar{
        display: none;
    }
}
.clip-path1 {
    width: 100%;
    height: 0px;
    background-color: #141414;
    max-width: 1200px;
    // transition: all 2s ease;
    // clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%);

    // clip-path: polygon(75% 0%, 75% 30%, 55% 30%, 55% 60%, 30% 60%, 30% 90%, 0% 90%, 0% 0%);
}
.clip-path-active {
    width: 100%;
    height: 400px;
    background-color: #141414;
    max-width: 1200px;
    animation-name: stairs;
    animation-duration: 4s;
    animation-fill-mode: forwards;
    transition: all 4s ease;
    // transition: clip-path 1s ease;
    // clip-path: polygon(75% 0%, 75% 30%, 55% 30%, 55% 60%, 30% 60%, 30% 90%, 0% 90%, 0% 0%);

    // clip-path: polygon(0% 0%, 75% 30%, 55% 30%, 55% 60%, 30% 60%, 30% 90%, 0% 90%, 0% 0%);
}
@keyframes stairs {
    0% {
        clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%);
        
    }
    25% {
        clip-path: polygon(75% 0%, 75% 30%, 55% 30%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%);

    }
    50% {
        clip-path: polygon(75% 0%, 75% 30%, 55% 30%, 55% 60%, 0% 0%, 0% 0%, 0% 0%, 0% 0%);

    }
    75% {
        clip-path: polygon(75% 0%, 75% 30%, 55% 30%, 55% 60%, 30% 60%, 30% 0%, 0% 0%, 0% 0%);

    }
    100% {
        clip-path: polygon(75% 0%, 75% 30%, 55% 30%, 55% 60%, 30% 60%, 30% 90%, 0% 90%, 0% 0%);

    }
}