@import "../../global.scss";

.scroll-up {
    position: fixed;
    bottom: 5%;
    right: -100px;
    width: 100px;
    height: 100px;
    z-index: 999999999;
    overflow: hidden;
    transition: all .3s ease;
    cursor: pointer;
}